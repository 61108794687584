/* Shared CSS parts */
#cover{
    height: calc( 100vh - #{ $nav-height } );

    @media screen and (min-width: $responsive-width){
        height: 100vh;
    }

    /* Set background picture */
    $bg-img-opacity: 0.2;
    background: linear-gradient(rgba(255,255,255,$bg-img-opacity), rgba(255,255,255,$bg-img-opacity)), url("../../../assests/images/cover/cover.jpg");
    background-position-x: 25%;
    background-size: auto 120vh;

    /* overrule general section CSS */
    padding: 0;
    margin: 0;
}

#coverInfo{
    border: 2px solid gray;
    border-radius: 2em;

    width: 90%;
    margin: 0 auto;
    padding: .8em;

    background: lightgrey;

    @include flex(row, nowrap, space-around);

     @media screen and (min-width: $responsive-width){
        width: 80%;
        max-width: 40em;
        margin: 12.5% 0 0 10%;
        padding: 1em 2em;
    }

    // all links
    a{
        color: black;
        text-decoration: none;
        &:hover{
            font-weight: bold;
            transform: scale(1.35)
        }
    }

    /* Left and right side of the box */
    > div{
        /* Flex equally over whole height */
        @include flex (column, nowrap, space-around)
    }

    /* left side */
    > div:first-child{
        text-align: right;
        font-size: unquote('min( 3.5vw, 1.35em)');
        flex: 3.5;

        > div:last-child{
            display: flex;
            justify-content: space-around;
        }
    }

    /* divider */
    > div:nth-child(2){
        flex: 1;   

        /* divider line is inside it */
        > div{
            border: 2px solid black;
            height: 100%;
            width: 0;
            margin: 0 auto;
        }
    }
    

    /* right side */
    > div:nth-child(3){
        flex: 6;
        height: 100%;

        /* name */
        > div:first-child{
            font-size: unquote('min(5vw, 2.5em)');
            font-weight: bold;
        }

        /* Skillset */
        > div:last-child{
            font-size: unquote('min(4vw, 1.6em)');
            font-style: italic;
            width: 100%;

            padding-top: .5em;
            padding-left: 8%;
        }
    }

     @media screen and (min-width: $responsive-width) and (max-width: #{ $responsive-width + 150px }){
        width: 80%;
        padding: .25em;
        margin: 0 auto;
        margin-top: 5em;
     }


}