/* education */
#education  > tbody > tr:first-child > td:first-child{
    /* horizontal center */
    text-align: center;
    /* vertical middle */
    vertical-align: middle;

    $responsive-width-minus250: calc( #{$responsive-width} - 250px);
    @media screen and (max-width: $responsive-width-minus250 ){ 
        display: none;
    }
}